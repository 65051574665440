<div class="flex items-center">

    @if(logoUrl() !== '') {

    <p-avatar [image]="logoUrl()" styleClass="bg-neutral-50 rounded overflow-hidden" size="large"
        (onImageError)="onLogoError()"></p-avatar>

    } @else {

    <p-avatar icon="pi pi-home" styleClass="rounded overflow-hidden" size="large"></p-avatar>

    }

    <div class="flex-1 ml-2 text-left">
        <div>{{ company() }}</div>
        <p-tag [value]="tenantRole()" styleClass="py-0 text-xs" />
    </div>

    <i class="pi pi-angle-right text-neutral-500" style="font-size: 1.5rem"></i>

</div>