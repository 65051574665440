import { Component, OnInit, output } from "@angular/core";
import { DatePipe } from "@angular/common";

import { NotificationsService, type WebsitePost } from "src/app/services";

import { ButtonModule } from "primeng/button";
import { Divider } from "primeng/divider";
import { DrawerModule } from "primeng/drawer";

@Component({
  selector: "app-notifications-panel",
  templateUrl: "./notifications-panel.component.html",
  styleUrl: "./notifications-panel.component.scss",
  standalone: true,
  imports: [Divider, ButtonModule, DrawerModule, DatePipe],
})
export class NotificationsPanelComponent implements OnInit {
  readonly onOpenInvite = output<void>();

  /** @ignore */
  constructor(private ns: NotificationsService) {}

  get showNotificationsSideBar() {
    return this.ns.showNotificationsSideBar;
  }

  set showNotificationsSideBar(value) {
    this.ns.showNotificationsSideBar = value;
  }

  get showEmpty(): boolean {
    return this.ns.notifications.every((n) => !n.show);
  }

  get notifications() {
    return this.ns.notifications;
  }

  get invite() {
    return this.ns.invite;
  }

  ngOnInit(): void {
    this.loadNotifications();
  }

  async loadNotifications(): Promise<void> {
    await this.ns.init();
  }

  openInvite(): void {
    this.ns.showNotificationsSideBar = false;
    this.ns.displayInviteModal = true;
  }

  async markAsRead(notification: WebsitePost): Promise<void> {
    await this.ns.hidePost(notification.id);
    notification.show = !notification.show;
  }

  async markAllAsRead(): Promise<void> {
    for (const n of this.ns.notifications) {
      n.show = false;
      await this.ns.hidePost(n.id);
    }
  }
}
