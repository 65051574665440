import { Component, computed, effect, signal } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";

import {
  ContractorService,
  AnalyticsService,
  CatchErrorService,
  AlertService,
  ANALYTICS_EVENTS,
} from "src/app/services";
import { IContractorStatus, type IContractor } from "src/app/models";

import { ButtonModule } from "primeng/button";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { FloatLabel } from "primeng/floatlabel";
import { IconField } from "primeng/iconfield";
import { InputIcon } from "primeng/inputicon";
import { Divider } from "primeng/divider";

@Component({
  selector: "app-contractor-edit-modal",
  templateUrl: "./contractor-edit-modal.component.html",
  styleUrls: ["./contractor-edit-modal.component.scss"],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    InputSwitchModule,
    ButtonModule,
    FloatLabel,
    IconField,
    InputIcon,
    Divider,
  ],
})
export class ContractorEditComponent {
  isSaving = signal<boolean>(false);
  isDeleting = signal<boolean>(false);
  /** contractor passed in or out */
  contractor = signal<IContractor | undefined>(undefined);
  /** contractor info form */
  contractorForm: FormGroup;
  /** [(ngModel)] */
  active: boolean = false;
  /** @ignore */
  constructor(
    private analytics: AnalyticsService,
    private cs: ContractorService,
    private cx: CatchErrorService,
    private alert: AlertService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig
  ) {
    this.contractorForm = new FormGroup({
      company: new FormControl("", { validators: [Validators.required] }),
      name: new FormControl(""),
      email: new FormControl(""),
      phone: new FormControl(""),
      discipline: new FormControl(""),
    });

    this.contractor.update(() => this.dialogConfig.data["contractor"]);

    effect(() => {
      const editMode = this.editMode();
      const contractor = this.contractor();

      if (editMode && contractor) {
        this.contractorForm.controls["company"].setValue(contractor.company);
        this.contractorForm.controls["name"].setValue(contractor.name);
        this.contractorForm.controls["email"].setValue(contractor.email);
        this.contractorForm.controls["phone"].setValue(contractor.phone);
        this.contractorForm.controls["discipline"].setValue(
          contractor.discipline
        );
        this.active = contractor.status === IContractorStatus.active;
      }
    });
  }

  /** Edit mode when input is no undefined */
  editMode = computed(() => this.contractor() !== undefined);

  /** disables stuff when operations are happening */
  loading = computed(() => this.isSaving() || this.isDeleting());

  /** Save contractor */
  async save(): Promise<void> {
    this.isSaving.update(() => true);

    try {
      const { company, name, email, phone, discipline } =
        this.contractorForm.value;
      const _company = `${company}`.trim();
      const _name = `${name}`.trim();
      const _email = `${email}`.trim();
      const _phone = `${phone}`.trim();
      const _discipline = `${discipline}`.trim();

      let saveContractor: IContractor;
      if (this.editMode()) {
        saveContractor = {
          ...this.contractor(),
          lastModified: this.contractor()?.lastModified || 0,
          lastMapChanged: this.contractor()?.lastMapChanged || 0,
          isAssigned: this.contractor()?.isAssigned || false,
          cloudId: this.contractor()?.cloudId,
          company: _company,
          name: _name,
          email: _email,
          phone: _phone,
          discipline: _discipline,
          status: this.active
            ? IContractorStatus.active
            : IContractorStatus.archived,
        };
      } else {
        saveContractor = {
          company: _company,
          name: _name,
          email: _email,
          phone: _phone,
          discipline: _discipline,
          cloudId: null,
          status: IContractorStatus.active,
          isAssigned: false,
          lastModified: Date.now(),
          lastMapChanged: 0, // not required
        };
      }

      const result = await this.cs.saveContractor(saveContractor);

      if (result.success) {
        this.analytics.trackCustomEvent(
          this.editMode()
            ? ANALYTICS_EVENTS.contractorEdited
            : ANALYTICS_EVENTS.contractorCreated
        );
        this.dismiss("refresh-page");
      } else {
        await this.alert.showAlert({ title: "Error", message: result.message });
      }
    } catch (ex) {
      this.cx.handle(ex);
    } finally {
      this.isSaving.update(() => false);
    }
  }

  /** Delete contractor */
  async delete(): Promise<void> {
    this.isDeleting.update(() => true);

    const contractor = this.contractor();

    try {
      if (contractor) {
        const result = await this.cs.deleteContractor(contractor);
        if (result.success) {
          this.analytics.trackCustomEvent(ANALYTICS_EVENTS.contractorDeleted);
          this.dismiss("refresh-page");
        } else {
          await this.alert.showAlert({
            title: "Error",
            message: result.message,
          });
        }
      }
    } catch (ex) {
      this.cx.handle(ex);
    } finally {
      this.isDeleting.update(() => false);
    }
  }

  /** Close the modal */
  private dismiss(action?: string): void {
    this.dialogRef.close({ role: action || "cancel" });
  }
}
