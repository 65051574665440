<div class="progress-bar-wrapper w-full flex flex-row items-stretch"
  [class.ready]="newCount() > 0 || inProgressCount() > 0 || completeCount() > 0" [style.height]="height()">

  @if(showCounts() && newCount() && newCount() > 0) {
  <div class="flex-1 flex flex-row items-center">
    <div class="progress-bar-number">{{newCount()}} New</div>
  </div>
  }

  @if(inProgressCount() > 0) {
  <div [style.width]="getInProgress()" class="progress-inprogress flex flex-row items-center">
    @if(showCounts()) {
    <div class="progress-bar-number">{{inProgressCount()}} In progress</div>
    }
  </div>
  }

  @if(completeCount() > 0) {
  <div [style.width]="getComplete()" class="progress-complete flex flex-row items-center">
    @if(showCounts()) {
    <div class="progress-bar-number">{{completeCount()}} Complete</div>
    }
  </div>
  }

</div>