<div class="flex flex-col items-stretch h-full surface-panel">

  <div class="p-2 md:p-3 mb-4 flex flex-row items-center mobile-toolbar">
    <div class="flex-1">
      <h1 class="ml-3 md:ml-0">
        @if(defect) {
        <span>{{defect.defectNumberString}}</span>
        } @else {
        <span>...</span>
        }
      </h1>
    </div>

    <div>
      @if(defect?.status !== 2) {
      <p-button icon="pi pi-save" [label]="editMode ? 'Update' : 'Save'" [loading]="saving"
        [pTooltip]="editMode ? 'Update existing' : 'Save new defect'" tooltipPosition="left"
        (click)="save()"></p-button>
      }

      <p-button icon="pi pi-ellipsis-h" class="ml-2" severity="secondary" [rounded]="true" [text]="true"
        pTooltip="Options" tooltipPosition="left" (click)="showPopMenu($event, popMenuCtrl)"></p-button>

      <p-button icon="pi pi-times" class="mr-3 md:mr-0" severity="secondary" [rounded]="true" [text]="true"
        pTooltip="Close Defect" tooltipPosition="left" (click)="close()"></p-button>
    </div>
  </div>

  <div class="p-2 md:p-0 flex-1 overflow-y-auto pb-8">
    <div class="flex flex-col flex-auto px-3">
      <ng-container [ngTemplateOutlet]="template"></ng-container>
    </div>
  </div>
</div>

<p-menu #popMenuCtrl [popup]="true" [style]="{minWidth: '220px'}" [model]="popoverMenuOptions" appendTo="body"></p-menu>