import {
  Component,
  ViewChild,
  ElementRef,
  input,
  computed,
  effect,
} from "@angular/core";
import { ImageModule } from "primeng/image";
import { trimString } from "../../helpers";

@Component({
  selector: "ia-avatar",
  templateUrl: "./ia-avatar.component.html",
  styleUrls: ["./ia-avatar.component.scss"],
  imports: [ImageModule],
})
export class IaAvatarComponent {
  /** canvas reference */
  @ViewChild("myCanvas", { static: true }) myCanvas:
    | ElementRef<HTMLCanvasElement>
    | undefined;
  /** required, will work as a full display name */
  fname = input.required<string, string>({
    transform: trimString,
  });
  /** optional */
  lname = input<string, string>("", {
    transform: trimString,
  });
  /** If provided, will display an image - optional */
  photoUrl = input<string>("");

  /** @ignore */
  constructor() {
    effect(() => {
      const initials = this.initials();
      this.drawCanvasAvatar(initials);
    });
  }

  showInitials = computed<boolean>(() => {
    return this.photoUrl() === "";
  });

  initials = computed<string>(() => {
    // If no lname provided, see if a secondary name exists in fname field
    let firstName: string = this.fname();
    let lastName: string = "";

    if (this.lname() === "") {
      const split = this.fname().split(" ");
      lastName = (split.length > 1 ? split[1] : "").trim();
    } else {
      lastName = this.lname();
    }

    return this.createInitials(firstName, lastName);
  });

  /**
   * Generate initials
   * @returns string length 2
   */
  createInitials(firstName: string, lastName: string): string {
    let initials = "";

    try {
      const count = Math.max(firstName.length, lastName.length);

      for (let i = 0; i < count; i++) {
        // if firstname has length, take a letter
        if (i <= firstName.length && firstName.charAt(i) !== " ") {
          initials += firstName.charAt(i).toUpperCase();
          // If two initials found, break;
          if (initials.length > 1) {
            break;
          }
        }
        // if lastname has length, take a letter
        if (i <= lastName.length && lastName.charAt(i) !== " ") {
          initials += lastName.charAt(i).toUpperCase();
          // If two initials found, break;
          if (initials.length > 1) {
            break;
          }
        }
      }
    } catch (ex) {
      //
    }

    return initials;
  }

  /**
   * Draw the avatar
   * - white text
   * - transparent background
   */
  drawCanvasAvatar(initials: string): void {
    const canvasSize = 80;
    const canvas = this.myCanvas!.nativeElement;

    let ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");
    if (ctx == null) return;

    // Set canvas with & height
    canvas.width = canvasSize;
    canvas.height = canvasSize;

    // Select a font family to support different language characters
    ctx.font = Math.round(canvas.width / 2) + "px Arial";
    ctx.textAlign = "center";

    // Setup background and front color
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "#FFF";
    ctx.fillText(initials, canvasSize / 2, canvasSize / 1.5);
  }
}
