import { Component, model } from "@angular/core";
import { ButtonModule } from "primeng/button";
import { IDefectStatus } from "src/app/models";

@Component({
  selector: "app-status-button-group",
  templateUrl: "./status-button-group.component.html",
  styleUrl: "./status-button-group.component.scss",
  imports: [ButtonModule],
})
export class StatusButtonGroupComponent {
  /** Accessed via [(ngModel)] */
  value = model<IDefectStatus | null | undefined>(null);
  /**
   * Button click event - propagate to output
   * @param value
   */
  select(value: IDefectStatus) {
    this.value.update(() => value);
  }
}
