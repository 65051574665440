<div class="text-sm mb-2">Outcome</div>
<p-select class="min-w-[200px]" [options]="reviewOptions" optionValue="id" [(ngModel)]="reviewStatus"
    placeholder="Select resolution" />

@if(reviewStatus === 1 && defectStatus() === 2) {
<p class="pl-1 text-sm text-red-500">Defect will be changed back to in progress.</p>
} @else {
<div class="p-2"></div>
}

<div class="mb-2 flex flex-row">
    <div [class.border-primary-500]="!notifyOwner" class="border-b-2">
        <p-button label="Add comment" variant="text" [severity]="!notifyOwner ? 'primary' : 'secondary'"
            (click)="notifyOwner = false"></p-button>
    </div>
    <!--  <div [class.border-primary-500]="notifyOwner" class="border-b-2">
       @if(!isEnterprise()) {
        <p-button label="Notify owner" title="Available on Enterprise" variant="text" severity="secondary"
            [disabled]="true"></p-button>
        } @else {
        <p-button label="Notify owner(s)" variant="text" [severity]="notifyOwner ? 'primary' : 'secondary'"
            (click)="notifyOwner = true"></p-button>
        }
    </div>  -->
    <div class="flex-1 border-b-2"></div>
</div>

<app-comments-text-editor #textEditor [placeholder]="'Add an optional comment...'" />

<!-- IMAGES -->
<div class="mt-2 p-2 rounded bg-neutral-50">
    <div class="w-full flex flex-row justify-start">
        <!-- ATTACH BUTTON -->
        <p-fileUpload mode="basic" chooseLabel="Attach Photos" #uploaderToolTwo [showUploadButton]="false" [auto]="true"
            [disabled]="photos.length === 3" [showCancelButton]="false" name="myfile[]" [multiple]="true"
            [customUpload]="true" accept="image/*" (onSelect)="selectCommentImages($event, uploaderToolTwo);"
            chooseStyleClass="p-button-sm p-button-info"></p-fileUpload>
    </div>

    @if (photos.length > 0) {
    <!-- IMAGE ROW -->
    <div class="mt-2 flex flex-row">
        @for (item of photos; track $index; let i = $index) {
        <div class="mr-2">
            <app-image-attachment [fullPath]="item.base64" [index]="i" [enablePreview]="false"
                (onDelete)="deletePhoto(i)"></app-image-attachment>
        </div>
        }
    </div>
    }
</div>


<div class="py-4 w-full flex flex-row justify-end">
    <p-button class="mr-2" label="Cancel" severity="info" [disabled]="saving()" (click)="dismiss()"></p-button>
    <p-button label="Update" [loading]="saving()" (click)="save()"></p-button>
</div>