import {
  Component,
  OnDestroy,
  input,
  CUSTOM_ELEMENTS_SCHEMA,
  OnInit,
  effect,
} from "@angular/core";

import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import { Placeholder } from "@tiptap/extension-placeholder";
import {
  TiptapEditorDirective,
  // TiptapFloatingMenuDirective,
  // TiptapBubbleMenuDirective,
} from "ngx-tiptap";

@Component({
  selector: "app-comments-text-editor",
  templateUrl: "./comments-text-editor.component.html",
  styleUrl: "./comments-text-editor.component.scss",
  imports: [
    TiptapEditorDirective,
    // TiptapFloatingMenuDirective,
    // TiptapBubbleMenuDirective,
    // TextEditorToolbarComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommentsTextEditorComponent implements OnInit, OnDestroy {
  initialValue = input<string>("");
  placeholder = input<string>("Add a comment...");
  protected editor: Editor;
  protected value: string = "";
  #dirty = false;
  /** @ignore */
  constructor() {
    this.editor = new Editor({
      extensions: [
        Placeholder.configure({
          placeholder: this.placeholder(),
          emptyEditorClass: "is-editor-empty",
        }),
        StarterKit.configure({
          dropcursor: false,
          heading: false,
          horizontalRule: false,
          blockquote: false,
          codeBlock: false,
          bulletList: false,
          orderedList: false,
          paragraph: {
            HTMLAttributes: {
              class: "prose-p !my-1",
            },
          },
        }),
      ],
      editorProps: {
        attributes: {
          class:
            "prose prose-neutral overflow-auto min-h-[100px] max-h-[300px] max-w-none py-2 px-3 bg-white border-neutral-300 focus:border-neutral-600 border-1 rounded-md outline-none",
        },
      },
      onFocus: (_) => {
        if (this.#dirty) return;
        this.#dirty = true;
      },
    });

    effect(() => {
      try {
        this.editor.extensionManager.extensions.forEach((extension) => {
          if (extension.name === "placeholder") {
            (extension.options as any).placeholder = this.placeholder();
          }
        });
      } catch (ex) {
        // ignore
        console.error(ex);
      }
    });
  }

  get text() {
    return this.editor.getText();
  }

  get isDirty() {
    return this.#dirty;
  }

  ngOnInit(): void {
    const content = this.initialValue() ?? "";
    this.editor.commands.setContent(content);
    if (content !== "") {
      this.#dirty = true;
    }
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  clear() {
    this.editor.commands.setContent("");
    this.#dirty = false;
  }
}
