<div class="p-3"></div>
<form [formGroup]="contractorForm">
  <!-- COMPANY -->
  <div class="field w-full mb-8">
    <p-floatlabel>
      <p-iconfield>
        <input type="text" pInputText id="inputCompany" class="w-full" autocapitalize="words" inputmode="text"
          formControlName="company">
        <p-inputicon>
          @if(contractorForm.controls['company'].valid) {
          <i class="pi pi-check-circle text-success"></i>
          } @else {
          <i class="pi pi-exclamation-circle text-danger"></i>
          }
        </p-inputicon>
      </p-iconfield>
      <label for="inputCompany">Company</label>
    </p-floatlabel>
  </div>
  <!-- NAME -->
  <div class="field w-full mb-8">
    <p-floatlabel>
      <input type="text" pInputText id="inputName" class="w-full" autocapitalize="words" inputmode="text"
        formControlName="name">
      <label for="inputName">Contractor Name</label>
    </p-floatlabel>
  </div>
  <!-- EMAIL -->
  <div class="field w-full mb-8">
    <p-floatlabel>
      <input type="text" pInputText id="inputEmail" class="w-full" autocapitalize="words" inputmode="email"
        formControlName="email">
      <label for="inputEmail">Email</label>
    </p-floatlabel>
  </div>
  <!-- PHONE -->
  <div class="field w-full mb-8">
    <p-floatlabel>
      <input type="text" pInputText id="inputPhone" class="w-full" inputmode="tel" formControlName="phone">
      <label for="inputPhone">Phone</label>
    </p-floatlabel>
  </div>
  <!-- DISCIPLINE -->
  <div class="field w-full mb-8">
    <p-floatlabel>
      <input type="text" pInputText id="inputDiscipline" class="w-full" autocapitalize="on" inputmode="text"
        formControlName="discipline">
      <label for="inputDiscipline">Discipline</label>
    </p-floatlabel>
  </div>

</form>

<!-- DISCIPLINE -->
@if(editMode()) {
<div class="w-full mb-8 flex flex-row items-center pl-2 pr-2">
  <div class="flex-1 pr-3">
    Enable Contractor<br />
    <span class="text-sm dms-color-medium">
      Deactivate to hide contractors from future projects.
    </span>
  </div>
  <p-inputSwitch [(ngModel)]="active"></p-inputSwitch>
</div>

<p-divider />
}


<!-- SAVE -->
<div class="text-center pb-2">
  <p-button label="Save Changes" size="small" [loading]="isSaving()"
    [disabled]="!contractorForm.controls['company'].valid || loading()" (click)="save()"></p-button>
</div>

@if(editMode()) {
<div class="text-center">

  <!-- DELETE -->
  <div class="p-2"></div>
  @if(contractor()?.isAssigned === true) {
  <span class="text-sm">
    This contractor is assigned to a project or defect and cannot be deleted.<br />You may use the disable option
    instead.
  </span>
  } @else {
  <p-button severity="danger" size="small" [text]="true" label="Delete Contractor" icon="pi pi-trash"
    [loading]="isDeleting()" [disabled]="loading()" (click)="delete()"></p-button>
  }
</div>
}