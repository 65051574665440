import { Component, effect, input, signal, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CatchErrorService, ApiService, AlertService } from "src/app/services";
import {
  DefectReviewOption,
  DefectReviewStatus,
} from "src/app/models/defect-review.model";

import { CommentsTextEditorComponent, ImageAttachmentComponent } from "..";

import { SelectModule } from "primeng/select";
import { ButtonModule } from "primeng/button";
import { CommentPhotoCreate } from "src/app/services/api/comments-api.service";
import {
  DefectPrimaryKeyId,
  IComment,
  ICommentPhoto,
  IDefectStatus,
  PhotoState,
  ProjectPrimaryKeyId,
} from "src/app/models";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import {
  FileSelectEvent,
  FileUpload,
  FileUploadModule,
} from "primeng/fileupload";
import { ProjectProvider } from "src/app/providers";

@Component({
  selector: "app-close-defect-dialog",
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommentsTextEditorComponent,
    ImageAttachmentComponent,
    SelectModule,
    ButtonModule,
    FileUploadModule,
  ],
  templateUrl: "./close-defect-dialog.component.html",
  styleUrl: "./close-defect-dialog.component.scss",
})
export class CloseDefectDialogComponent {
  @ViewChild("textEditor", { read: CommentsTextEditorComponent })
  private textEditor: CommentsTextEditorComponent | undefined;
  /** defect id to set */
  projectId = input.required<ProjectPrimaryKeyId>();
  /** defect id to set */
  defectId = input.required<DefectPrimaryKeyId>();
  /** Passed in to check if the status has to change based on review outcome */
  defectStatus = input.required<IDefectStatus>();
  /** auto set the status based on input value */
  reviewStatusInput = input.required<DefectReviewStatus>();
  /** disabled the send to owner option */
  isEnterprise = input.required<boolean>();
  /** ngModel */
  reviewStatus: DefectReviewStatus = 1;
  /** ngModel */
  notifyOwner: boolean = false;
  /** Not in use */
  photos: CommentPhotoCreate[] = [];
  /** Select options */
  readonly reviewOptions: DefectReviewOption[] = [
    { label: "Complete", id: DefectReviewStatus.COMPLETE },
    { label: "Incomplete", id: DefectReviewStatus.NOT_REVIEWED },
    { label: "Not a Defect", id: DefectReviewStatus.NOT_A_DEFECT },
  ];
  /** */
  saving = signal<boolean>(false);
  /** @ignore */
  constructor(
    private readonly cx: CatchErrorService,
    private readonly api: ApiService,
    private dialogRef: DynamicDialogRef,
    private alert: AlertService,
    private pp: ProjectProvider
  ) {
    effect(() => {
      const inputStatus = this.reviewStatusInput();
      if (this.reviewStatus !== inputStatus) {
        this.reviewStatus = inputStatus;
      }
    });
  }

  async save(): Promise<void> {
    try {
      let comment = this.textEditor?.text ?? "";

      // if images were added, add a placeholder comment if it is blank.
      if (comment.trim() === "" && this.photos.length > 0) {
        comment = "No comment provided.";
      }

      if (
        comment === "" &&
        this.pp.defect?.reviewStatusId === this.reviewStatus
      ) {
        // no changes
        this.dismiss();
      }

      let newDefectStatus = this.defectStatus();
      let completedDateString = "";
      switch (this.reviewStatus) {
        case DefectReviewStatus.NOT_REVIEWED:
          if (newDefectStatus === IDefectStatus.COMPLETE) {
            newDefectStatus = IDefectStatus.IN_PROGRESS;
          }
          break;
        case DefectReviewStatus.COMPLETE:
        case DefectReviewStatus.NOT_A_DEFECT:
          if (newDefectStatus !== IDefectStatus.COMPLETE) {
            completedDateString = new Date().toISOString();
          }
          newDefectStatus = IDefectStatus.COMPLETE;
          break;
      }

      this.saving.update(() => true);

      // Update the review status second
      const reviewResult = await this.api.comments.setReviewStatus(
        this.defectId(),
        comment,
        this.reviewStatus,
        newDefectStatus,
        completedDateString,
        this.photos
      );

      if (reviewResult.success) {
        let commentData: IComment | undefined;

        if (reviewResult.data.comment) {
          let photos: ICommentPhoto[] = [];

          if (reviewResult.data.comment.photos) {
            if (reviewResult.data.comment.photos.length > 0) {
              for (const photo of reviewResult.data.comment.photos) {
                photos.push({
                  cloudId: photo.cloudId,
                  filename: photo.filename,
                  fullPath: photo.fullPath,
                  thumbnail: photo.thumbnail,
                  state: PhotoState.current,
                });
              }
            }
          }

          commentData = {
            cloudId: reviewResult.data.comment.cloudId,
            comment: comment,
            userId: -1,
            username: "",
            created: reviewResult.data.lastModified,
            lastModified: reviewResult.data.lastModified,
            photos: photos,
          };
        }

        this.pp.onDefectReviewChange(
          this.defectId(),
          this.reviewStatus,
          newDefectStatus,
          commentData
        );
        this.dismiss();
      } else {
        this.alert.showToast(reviewResult.message, "error");
      }
    } catch (ex) {
      this.cx.handle(ex, true);
    } finally {
      this.saving.update(() => false);
    }
  }

  /**
   * Runs when files are selected via the picker
   *
   * @param {_UploaderSelectEvent} event - the _UploaderSelectEvent
   * @param {FileUpload} uploaderTool - the FileUpload
   * @return {Promise<void>} a Promise that resolves to void
   */
  async selectCommentImages(
    event: FileSelectEvent,
    uploaderTool: FileUpload
  ): Promise<void> {
    try {
      if (this.photos.length === 3) return;

      const files: File[] = event?.currentFiles ?? [];

      if (files.length < 1) return;

      for (let i = 0; i < files.length; i++) {
        const file: File = files[i];

        // Only process image files.
        if (!file.type.match("image.*")) {
          continue;
        }

        // Closure to capture the file information.
        const reader = new FileReader();
        reader.onload = (o: ProgressEvent<FileReader>) => {
          const timestamp = new Date().getTime();
          const newPhoto: CommentPhotoCreate = {
            base64: (o.target?.result as string) ?? "",
            filename: `${timestamp}.jpeg`,
          };

          if (this.photos.length < 3) {
            this.photos.push(newPhoto);
          }

          uploaderTool.clear();
        };
        reader.onerror = (o: ProgressEvent<FileReader>) => {
          console.error(o);
          uploaderTool.clear();
        };
        reader.readAsDataURL(file);
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  /** Marks a photo for deletion */
  deletePhoto(index: number): void {
    this.photos.splice(index, 1);
  }

  dismiss(): void {
    this.dialogRef.close();
  }
}
