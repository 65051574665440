import { Component, input, output, computed } from "@angular/core";

@Component({
  selector: "app-image-attachment",
  templateUrl: "./image-attachment.component.html",
  styleUrls: ["./image-attachment.component.scss"],
})
export class ImageAttachmentComponent {
  /** Image index */
  index = input<number>();
  /** Image index */
  fullPath = input<string>();
  /** Hides the delete button */
  enabled = input<boolean>();
  /** Hides the preview icon */
  enablePreview = input<boolean>(true);
  /** Trigger when preview image runs */
  readonly onPreview = output<any>();
  /** Trigger when preview image runs */
  readonly onDelete = output<any>();

  constructor() {}

  enabledDelete = computed<boolean>(() => {
    return this.enabled() !== false;
  });

  preview(): void {
    this.onPreview.emit(this.index());
  }

  delete(): void {
    this.onDelete.emit(this.index());
  }
}
