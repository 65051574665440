import { Component, computed, input } from "@angular/core";

@Component({
  selector: "defect-summary-micro-chart",
  templateUrl: "./summary-micro-chart.component.html",
  styleUrls: ["./summary-micro-chart.component.scss"],
  standalone: true,
  imports: [],
})
export class SummaryMicroChartComponent {
  readonly defaultHeight: number = 14;
  newCount = input<number>(0);
  inProgressCount = input<number>(0);
  completeCount = input<number>(0);
  showCounts = input<boolean>(false);

  constructor() {}

  height = computed<string>(() =>
    this.showCounts() ? "inherit" : `${this.defaultHeight}px`
  );

  getInProgress = computed<string>(() => {
    const inProgressPercentage =
      this.inProgressCount && this.inProgressCount() > 0
        ? Math.round((this.inProgressCount() / this.getTotal()) * 100)
        : 0;
    return inProgressPercentage > 0 ? `${inProgressPercentage}%` : "0";
  });

  getComplete = computed<string>(() => {
    const completedPercentage =
      this.completeCount && this.completeCount() > 0
        ? Math.round((this.completeCount() / this.getTotal()) * 100)
        : 0;
    return completedPercentage > 0 ? `${completedPercentage}%` : "0";
  });

  /** return total number of counts for all type */
  getTotal = computed<number>(() => {
    return (
      (this.newCount() ?? 0) +
      (this.inProgressCount() ?? 0) +
      (this.completeCount() ?? 0)
    );
  });
}
