import {
  Component,
  ContentChild,
  TemplateRef,
  Input,
  output,
} from "@angular/core";
import { NgTemplateOutlet } from "@angular/common";

import { MenuItem } from "primeng/api";
import { Defect } from "src/app/models";
import { MenuModule } from "primeng/menu";
import { ButtonModule } from "primeng/button";
import { TooltipModule } from "primeng/tooltip";

@Component({
  selector: "app-defect-dialog",
  templateUrl: "./defect-dialog.component.html",
  styleUrls: ["./defect-dialog.component.scss"],
  imports: [ButtonModule, NgTemplateOutlet, MenuModule, TooltipModule],
})
export class DefectDialogComponent {
  @Input() defect: Defect.Defect | undefined;
  @Input() showDefectInModal: boolean = false;
  @Input() saving: boolean = false;
  @ContentChild(TemplateRef) template!: TemplateRef<any>;
  readonly onSave = output<void>();
  readonly onClose = output<void>();
  readonly onToggleModalPanelView = output<void>();
  //
  protected popoverMenuOptions: MenuItem[];
  constructor() {
    this.popoverMenuOptions = [
      {
        label: "Options",
      },
    ];
  }

  get editMode() {
    return (this.defect?.cloudId ?? 0) > 0;
  }

  protected save(): void {
    this.onSave.emit();
  }

  /**
   * Close the defect and clear the related defect data
   */
  protected close(): void {
    this.onClose.emit();
  }
  /**
   * Displays the popover menu
   */
  protected showPopMenu(event: any, menuCtrl: any): void {
    this.popoverMenuOptions = [
      {
        label: "Options",
        items: [
          {
            label: this.showDefectInModal
              ? "Open in sidebar"
              : "Open in window",
            icon: this.showDefectInModal
              ? "pi pi-sign-in"
              : "pi pi-window-maximize",
            command: () => this.toggleModalPanelView(),
          },
        ],
      },
    ];
    menuCtrl.toggle(event);
  }
  /**
   * Use showDefectInPanel as default when settings other values
   */
  protected async toggleModalPanelView(): Promise<void> {
    this.onToggleModalPanelView.emit();
  }
}
