import { Component, computed, effect, input, signal } from "@angular/core";
import { UserTenant } from "src/app/models/user-tenant.model";

import { AvatarModule } from "primeng/avatar";
import { TagModule } from "primeng/tag";

@Component({
  selector: "app-tenant-select-button",
  imports: [AvatarModule, TagModule],
  templateUrl: "./tenant-select-button.component.html",
  styleUrl: "./tenant-select-button.component.scss",
})
export class TenantSelectButtonComponent {
  item = input<UserTenant>();
  logoFailed = signal<boolean>(false);

  constructor() {
    effect(() => {});
  }

  logoUrl = computed(() => {
    if (this.logoFailed()) {
      return "";
    } else {
      return this.item()?.logoUrl ?? "";
    }
  });
  
  company = computed(() => this.item()?.company ?? "Unnamed");
  tenantRole = computed(() => this.item()?.tenantRole ?? "User");

  onLogoError(): void {
    this.logoFailed.update(() => true);
  }
}
