<div class="defect-p-image defect-thumbnail-wrapper" (click)="preview()">

  <div class="defect-thumbnail-hover flex flex-auto justify-around items-center">

    <div class="m-2 defect-thumbnail-controls flex flex-row">

      @if(enabledDelete()) {
      <div class="ml-2 flex flex-auto justify-around items-center defect-thumbnail-control"
        (click)="delete(); $event.stopPropagation();">
        <i class="pi pi-trash"></i>
      </div>
      }

    </div>

    @if(enablePreview()) {
    <i class="pi pi-eye"></i>
    }

  </div>

  @if(fullPath()) {
  <div class="fill">
    <img [src]="fullPath()" alt="Defect Image" />
  </div>
  }

</div>